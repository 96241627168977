import React from "react"
import * as aboutMeDarkStyle from "./aboutme-dark.module.css"
import { graphql, useStaticQuery } from "gatsby"
import ImageLoad from "./image-load"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faWater,
  faLaptopCode,
  faFileCode,
  faSuitcaseRolling,
  faNetworkWired,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons"

const AboutMeDark = () => {
  const aboutMeDarkQuery = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "aboutMe.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <Container>
      <Row className={aboutMeDarkStyle.rowStyle}>
        <Col md={{ order: 3 }}>
          <ImageLoad
            data={aboutMeDarkQuery}
            style={{ borderRadius: "50%", maxWidth: "50%", margin: "0 auto" }}
          />
        </Col>
        <Col sm={{ span: 4, order: 2 }} className={aboutMeDarkStyle.sayingCol}>
          <p className={aboutMeDarkStyle.sayingText}> Because it's there. </p>
          <p className={aboutMeDarkStyle.saidByText}>-George Mallory</p>
        </Col>
      </Row>
      <Row className={aboutMeDarkStyle.rowStyle}>
        <Col>
          <div className={aboutMeDarkStyle.aboutMeText}>
            Hey you unfortunate, unsuspecting folks, &ensp;
            <FontAwesomeIcon
              icon={faUserSecret}
              size="lg"
              style={{ color: "lightslategray" }}
            />
            &ensp;
            <br />
            <br />
            Seems like you and I have some similarities which google foresaw while running it's GOD Algorithm.
            How else would you have stumbled upon my personal blog with ocassional ramblings of subjects as
            divergent as technology and dreamcatchers?
            <br />
            Well initially, I had started working on my this piece to learn
            a bit of UI since I am predominantly a backend developer that too Java (not as prehistoric as C but
            nevertheless antedeluvian). Later it became a memory pad for me as I started writing out articles of interest
            which I could use for future references. It furthur turned into a learning mechanism which made me go in-depth
            into certain concepts and think about the piece from every aspect. It still does, and I hope it has
            turned out to be incisive if not illuminating for the readers.
            <br />
            <br />
            Little extra bit about myself which I hope you guys don't hold me up
            against:
            <br />
            Born and Bought up on the coastal regions of Kochi.&ensp;
            <FontAwesomeIcon
              icon={faWater}
              size="lg"
              style={{ color: "deepskyblue" }}
            />
            &ensp; The almost pristine beaches and refreshing coconuts are taken
            for granted here and the humid weather is forever your constant (You
            ain't alone here. There are always either some drops of skin urine
            or the eyes of some snoopy aunty on you.).
            <br />
            After my B.Tech, fresh out of the box minion (Me) started out as a Developer
            in Deloitte. &ensp;
            <FontAwesomeIcon
              icon={faLaptopCode}
              size="lg"
              style={{ color: "lightsteelblue" }}
            />
            &ensp; Currenly in Walmart as a Software Engineer. &ensp;
            <FontAwesomeIcon
              icon={faFileCode}
              size="lg"
              style={{ color: "lightsteelblue" }}
            />
            &ensp; I will forever be interested in new technologies &ensp;
            <FontAwesomeIcon
              icon={faNetworkWired}
              size="lg"
              style={{ color: "lightskyblue" }}
            />
            &ensp; and travel pursuits &ensp;
            <FontAwesomeIcon
              icon={faSuitcaseRolling}
              size="lg"
              style={{ color: "lightskyblue" }}
            />
            &ensp; with a passion that's slow yet relentless to understand and
            glean its purpose.
            <br />
            <br />
            Enough going on my endless narcissim, but feel free to check out
            this blog for articles related to my travels and technical exposure.
            Eventhough its not upto mastery level, we all gotta start somewhere
            even if its from the bottom of the pit. And all suggestions are
            welcome to be thrown into the Spam folder and any kind of negativity
            won't tolerated (Except mine, of course!).
            <br />
            <br />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutMeDark
