import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

function ImageLoad({ data, style }) {
  if (!data?.placeholderImage?.childImageSharp?.gatsbyImageData) {
    return <div>Picture not found</div>
  }

  return (
    <GatsbyImage
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      style={style}
    />
  )
}

export default ImageLoad
